var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Tooltips ")]),
              _c("small", [
                _c("code", [_vm._v("v-c-tooltip")]),
                _vm._v(" directive")
              ]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/oovue/docs/directives/tooltip",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CRow",
                [
                  _c("CCol", { attrs: { col: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center my-3" },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip.hover.click",
                                value: "I am a tooltip!",
                                expression: "'I am a tooltip!'",
                                modifiers: { hover: true, click: true }
                              }
                            ],
                            attrs: { color: "secondary" }
                          },
                          [_vm._v(" Hover Me ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("CCol", { attrs: { col: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "text-center my-3" },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  content: "I start open!",
                                  active: true
                                },
                                expression:
                                  "{content: 'I start open!', active:true }"
                              }
                            ],
                            attrs: { color: "secondary" }
                          },
                          [_vm._v(" Hover me ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Tooltips ")]),
              _c("small", [_vm._v("placement")])
            ],
            1
          ),
          _c("CCardBody", [
            _c(
              "div",
              { staticClass: "my-3" },
              [
                _c(
                  "CRow",
                  _vm._l(_vm.placements, function(placement) {
                    return _c(
                      "CCol",
                      {
                        key: placement,
                        staticClass: "py-4 text-center",
                        attrs: { md: "4" }
                      },
                      [
                        _c(
                          "CButton",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip.hover",
                                value: {
                                  content: "Placement " + placement,
                                  placement: placement
                                },
                                expression:
                                  "{\n                content: `Placement ${placement}`,\n                placement\n              }",
                                modifiers: { hover: true }
                              }
                            ],
                            attrs: { color: "primary" }
                          },
                          [_vm._v(" " + _vm._s(placement) + " ")]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }